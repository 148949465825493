<template>
  <div class="view">
    <div
      class="panel flex-column full-height"
    >
      <div class="page-header">
        <h1 class="flex-expand">
          Campaigns & Communications
        </h1>
        <NavFunctions
          class="nav-functions"
        />
      </div>
      <div class="flex-container">
        <div class="left-col">
          <!-- RECOMMENDATION SECTION -->
          <div
            v-if="!loadingCampaignRecommendations && !loadingTemplateRecommendations"
            class="flex-section light-blue-bg"
            style="flex: 1; min-height: 200px;"
          >
            <div class="flex-section-header">
              <img
                :src="recommendationIconSrc"
                class="medium-icon"
                alt="Recommendation Icon"
              >
              <h3 class="flex-expand blue-color">
                Recommended
              </h3>
              <button
                class="header-button"
                style="margin-left: auto; font-family: Montserrat, Helvetica, Arial, sans-serif;"
                @click="openRecommendationsModal({})"
              >
                View All
              </button>
            </div>
            <h4 class="blue-color">
              New
            </h4>
            <div
              v-if="recommendations.length > 0"
              class="y-scroll flex-column"
            >
              <div
                v-for="(recommendation,index) in recommendations"
                :key="index"
                class="recommendation-item flex-row"
                @click="openRecommendationsModal(recommendation)"
              >
                <h4 class="color-blue flex-expand">
                  <span
                    class="blue-color"
                  >{{ recommendation.name }} : </span><span class="recommendation-type">{{ recommendation.type==='campaign' ? "Campaign" :"Template" }}</span>
                </h4>
                <Tag
                  v-if="recommendation.new"
                  severity="success"
                  class="tag-success"
                  :value="'NEW'"
                />
              </div>
            </div>
            <div
              v-else
              class="flex-section"
            >
              No new recommended campaigns / templates
            </div>
          </div>
          <Skeleton
            v-else
            class="flex-section light-blue-bg"
            style="flex: 1; min-height: 200px;"
          />
          <!-- CAMPAIGN SECTION -->
          <div
            v-if="!loadingCampaignList"
            class="flex-section grey-bg"
            style="flex: 2; min-height: 250px;"
          >
            <div class="flex-section-header">
              <h3 class="flex-expand grey-color">
                Campaigns
              </h3>
              <button
                class="header-button"
                style="margin-left: auto; font-family: Montserrat, Helvetica, Arial, sans-serif;"
                @click="openCampaignsModal({})"
              >
                View All
              </button>
            </div>
            <h4 class="grey-color">
              Active
            </h4>
            <!-- CAMPAIGN LIST -->
            <div
              v-if="campaigns.length > 0"
              class="flex-column flex-item"
            >
              <VirtualScroller
                ref="virtualScroller"
                :items="campaigns"
                lazy
                :item-size="130"
                class="flex-item"
                :loading="loadingCampaignList"
                @lazy-load="lazyLoadActiveCampaigns"
              >
                <template #item="{ item }">
                  <CampaignCard
                    :campaign="item"
                    @click="openCampaignsModal(item)" 
                  />
                </template>
              </VirtualScroller>
            </div>
            <div
              v-else
              class="flex-section"
            >
              No active campaigns
            </div>
          </div>
          <Skeleton
            v-else
            class="flex-section grey-bg"
            style="flex: 2; min-height: 250px;"
          />
        </div>
        <!-- RIGHT COLUMN -->
        <div class="right-col">
          <h3 class="grey-color">
            My Pharmacies
          </h3>
          <!-- SEARCH ROW -->
          <div class="flex-row">
            <!-- <Calendar
              id="date_range"
              v-model="dateRange"
              show-icon
              icon-display="input"
              date-format="dd-mm-yy"
              placeholder="dd/mm/yyyy - dd/mm/yyyy"
              class="grey-input"
              selection-mode="range"
            /> -->
            <span class="p-input-icon-right">
              <i class="pi pi-search"></i>
              <InputText
                v-model="searchString"
                placeholder="Search"
                class="grey-input"
                style="font-family: Montserrat, Helvetica, Arial, sans-serif;;"
                @input="searchPharmacies"
              />
            </span>
            <div
              class="data-box button unselected-box"
              style="width:200px"
            >
              <p>
                <i
                  class="chevron pi-chevron-left pi"
                  @click="changeTimePeriod(-1)"
                ></i>
                <strong>{{ timePeriodQuarterYearMonth }}</strong>
                <i
                  v-if="rightTimePeriodArrowVisible"
                  class="chevron pi-chevron-right pi"
                  @click="changeTimePeriod(1)"
                ></i>
              </p>
            </div>
            <!-- 
              Open the settings modal on click
             -->
            <i
              class="button pi-sliders-h pi"
              @click="openTimePeriodModal({})"
            ></i>
            <div
              class="data-box button selected-box"
              style="margin-left: auto;"
              @click="openTemplatesModal()"
            >
              <p>
                <strong>Templates</strong>
              </p>
            </div>
          </div>
          <!-- TABLE -->
          <DataTable
            v-model:expanded-rows="expandedMessageStats"
            :row-class="rowClass"
            :value="messageStats"
            :loading="loadingMessageStats"
            scrollable
            scroll-height="flex"
            :auto-layout="true"
            paginator
            lazy
            :first="0"
            :total-records="messageStatsPerPage*messageStatsPageCount" 
            :rows="messageStatsPerPage + 1"
            :paginator-position="'bottom'"
            style="min-height: 0;"
            @page="getMessageStats($event)"
          >
            <template #empty>
              No records found
            </template>
            <template #loading>
              Loading records, please wait...
            </template>
            <Column
              field="rank"
              header="Rank"
              :sortable="true" 
            />
            <Column
              field="pharmacy_name"
              header="Pharmacy"
            >
              <template #body="slotProps">
                <span
                  v-if="slotProps.data.rank !== 'TOTAL'"
                  class="pharmacy-name"
                >
                  {{ companyPharmacies.find(pharmacy => pharmacy.pharmacy_id === slotProps.data.pharmacy_id)?.pharmacy_name || 'Unknown' }}
                </span>
                <span
                  v-else
                  class="pharmacy-name"
                >
                  {{ slotProps.data.pharmacy_name }}
                </span>
              </template>
            </Column>
            <Column
              field="sms"
              header="SMS"
            >
              <template #body="slotProps">
                {{ slotProps.data.sms !== null ? slotProps.data.sms : '-' }}
              </template>
            </Column>
            <Column
              field="email"
              header="Email"
            >
              <template #body="slotProps">
                {{ slotProps.data.email !== null ? slotProps.data.email : '-' }}
              </template>
            </Column>
            <Column
              field="reach"
              header="Reach"
            >
              <template #body="slotProps">
                {{ slotProps.data.reach !== null ? slotProps.data.reach : '-' }}
              </template>
            </Column>
            <Column
              header="Cost"
            >
              <template
                #body="slotProps"
              >
                {{ slotProps.data.reach !== null ? $n(slotProps.data.cost, 'currency') : '-' }}
              </template>
            </Column>
            <Column
              header="Revenue"
            >
              <template #body="slotProps">
                {{ slotProps.data.revenue !== null ? (slotProps.data.revenue > 0 ? "+" + $n(slotProps.data.revenue, 'currency') : $n(slotProps.data.revenue, 'currency')) : '-' }}
              </template>
            </Column>
            <Column
              header="ROI"
            >
              <template
                #body="slotProps"
              >
                <!-- <span style="margin-right: 1em">
                  ${{ slotProps.data.roi }}
                </span> -->
                <span
                  v-if="slotProps.data.roi !== null"
                  :class="slotProps.data.roi > 0 ? 'green-color' : 'red-color'"
                >                  
                  {{ slotProps.data.roi > 0 ? '+' : '' }}{{ slotProps.data.roi }}%
                </span>
                <span
                  v-else
                >
                  -
                </span>
              </template>
            </Column>
            
            <Column
              expander
              style="width: 5rem"
            />
            <!-- EXPANDABLE SECTION -->
            <template
              #expansion="slotProps"
            >
              <PharmacyMarketingDetails
                v-if="!slotProps.data.pharmacy_message_stats_loading"
                :pharmacy="slotProps.data"
              />
              <Skeleton
                v-else
                class="flex-section"
                style="min-height: 200px;"
              />
            </template>
          </DataTable>
        </div>
      </div>
    </div>
    <!-- 
      Recommendations Modal
     -->
    <RecommendationsModal
      v-if="displayRecommendationsModal"
      :recommendations="recommendations"
      :selected-recommendation="selectedRecommendation"
      :company-pharmacies="companyPharmacies"
      @close-modal="closeModal"
      @recommended-campaign-created="recommendedCampaignCreated"
      @recommended-template-created="recommendedTemplateCreated"
    />
    <!-- 
      Campaigns Modal
     -->
    <CampaignsModal
      v-if="displayCampaignsModal"
      :campaigns="campaigns"
      :selected-campaign="selectedCampaign"
      :company-pharmacies="companyPharmacies"
      @campaigns-modified="getCampaignList"
      @campaign-removed="campaignRemoved"
      @close-modal="closeModal"
    />
    <!-- 
      Templates Modal
     -->
    <TemplatesModal
      v-if="displayTemplatesModal"
      @close-modal="closeModal"
    />
    <!-- Time Period Modal-->
    <TimePeriodModal
      v-if="displayTimePeriodModal"
      :months="months"
      :quarters="quarters"
      :years="years"
      :selected-period-type="selectedPeriodType"
      :selected-period="selectedPeriod"
      @update-filters="updateFilters"
      @close-modal="closeModal"
    />
  </div>
</template>

<script>
import NavFunctions from '@/components/navigation/nav-menu-dropdown.vue';
import RecommendationsModal from '@/components/modals/recommendation-modal.vue';
import CampaignsModal from '@/components/modals/campaign-modal.vue';
import TemplatesModal from '@/components/modals/templates-modal.vue';
import PharmacyMarketingDetails from '@/components/tables/pharmacy-marketing-details.vue'
import TimePeriodModal from '@/components/modals/time-period-modal.vue';
import axios from 'axios';
import VirtualScroller from 'primevue/virtualscroller';
import CampaignCard from '@/components/campaign/campaign-card.vue';

// import axios from 'axios';

export default {
  components: {
    NavFunctions,
    RecommendationsModal,
    PharmacyMarketingDetails,
    CampaignsModal,
    TemplatesModal,
    TimePeriodModal,
    VirtualScroller,
    CampaignCard
},
  data() {
    return {
      recommendationIconSrc: require('@/assets/icons/recommendation_blue_icon.svg'),
      checkDisabledIconSrc: require('@/assets/icons/check_disabled_icon.svg'),
      checkEnabledIconSrc: require('@/assets/icons/check_enabled_icon.svg'),
      dateRange: null,
      searchString: '',
      campaignRecommendations: [],
      templateRecommendations: [],
      selectedRecommendation: null,
      loadingCampaignList: true,
      campaignListError: '',
      campaigns: [],
      campaignListCurrentPage: 1,
      campaignListPerPage: 1,
      campaignListPageCount: 1,
      selectedCampaign: null,
      companyPharmacies: [],
      templates: [],
      selectedTemplate: null,
      templateListCurrentPage: 1,
      templateListPerPage: 1,
      templateListPageCount: 1,
      templateListError: '',
      // Example row and column data for table
      rowData: [
        { rank: "TOTAL", pharmacy: "10 Pharmacies", sms: 13, email: 2, reach: 125, cost: 45, scripts: 88, revenue: 254, roi: 350, roi_change_percent: 13 },
        { rank: "1", pharmacy: "Pharmacy 1", sms: 13, email: 2, reach: 125, cost: 45, scripts: 88, revenue: 254, roi: 350, roi_change_percent: 13, revenue_change_percent: 13, cost_change_percent: 13, scripts_change_percent: -13, reach_change_percent: 13  },
        { rank: "2", pharmacy: "Pharmacy 2", sms: 13, email: 2, reach: 125, cost: 45, scripts: 88, revenue: 254, roi: 350, roi_change_percent: 13, revenue_change_percent: 13, cost_change_percent: 13, scripts_change_percent: -13, reach_change_percent: 13  },
        { rank: "3", pharmacy: "Pharmacy 2", sms: 13, email: 2, reach: 125, cost: 45, scripts: 88, revenue: 254, roi: 350, roi_change_percent: -13, revenue_change_percent: 13, cost_change_percent: 13, scripts_change_percent: -13, reach_change_percent: 13  }

      ],
      messageStats: [],
      loadingMessageStats: true,
      expandedMessageStats: [],
      messageStatsCurrentPage: 1,
      messageStatsPerPage: 1,
      messageStatsPageCount: 1,
      expandedCampaignsRows: [],
      loadingCampaignRecommendations: false,
      loadingTemplateRecommendations: false,
      displayRecommendationsModal: false,
      displayCampaignsModal: false,
      displayTemplatesModal: false,
      displayTimePeriodModal: false,
      selectedPeriod: {
          year: new Date().getFullYear(),
          month: null,
          quarter: null
      },
      years: [],
      months: [
        {
          name: 'January',
          value: 1
        },
        {
          name: 'February',
          value: 2
        },
        {
          name: 'March',
          value: 3
        },
        {
          name: 'April',
          value: 4
        },
        {
          name: 'May',
          value: 5
        },
        {
          name: 'June',
          value: 6
        },
        {
          name: 'July',
          value: 7
        },
        {
          name: 'August',
          value: 8
        },
        {
          name: 'September',
          value: 9
        },
        {
          name: 'October',
          value: 10
        },
        {
          name: 'November',
          value: 11
        },
        {
          name: 'December',
          value: 12
        }
      ],
      quarters: [
        {
          name: 'Q1',
          value: 1
        },
        {
          name: 'Q2',
          value: 2
        },
        {
          name: 'Q3',
          value: 3
        },
        {
          name: 'Q4',
          value: 4
        }
      ],
      selectedPeriodType: 'Calendar Year',
      delayTimer: null,
      companyPharmaciesFiltered: [],
    }
  },
  computed: {
    /**
     * Returns the recommendations
     * @returns {Array} recommendations
     */
    recommendations() {
      return [...this.campaignRecommendations, ...this.templateRecommendations];
    },
    /**
    * Returns the payload for the campign list request
    * @returns {Object} campaignListPayLoad
    */
    campaignListPayLoad() {
      return {
        "page": this.campaignListCurrentPage,
        "active": true
      }
    },
    /**
    * Returns the payload for the template list request
    * @returns {Object} templateListPayLoad
    */
    templateListPayLoad() {
      return {
        "page": 1
      }
    },
    /**
     * Returns the payload for the recommendations request
      * @returns {Object} recommendationsPayLoad
      */
    recommendationsPayLoad() {
      if (this.deploymentEnvironment === 'staging' || this.deploymentEnvironment === 'development') {
        return {
          "page": 1
        }
      }
      else {
        return {
          "page": 1,
          "new": true
        }
      }

    },
    /**
     * Returns the payload for the get message stats request
     * @returns {Object} messageStatsPayLoad
     */
    messageStatsPayLoad() {
      return {
        "year": this.selectedPeriod.year,
        "month": this.selectedPeriod.month,
        "quarter": this.selectedPeriod.quarter,
        "page": this.messageStatsCurrentPage,
        "pharmacy_ids": this.companyPharmaciesFiltered.map(x => x.pharmacy_id)
      }
    },
    /**
     * Get the month name from the integer month
     * return {String} - The month name
     */
    timePeriodQuarterYearMonth() {
      if (this.selectedPeriod.quarter) {
        return "Q" + this.selectedPeriod.quarter + " " + this.selectedPeriod.year;
      } else if (this.selectedPeriod.month) {
        return this.months.find(x => x.value === this.selectedPeriod.month).name + " " + this.selectedPeriod.year;
      } else {
        return this.selectedPeriod.year;
      }
    },
    /**
     * Get the right arrow visibility
     * return {Boolean} - The right arrow visibility
     */
    rightTimePeriodArrowVisible() {
      const currentYear = new Date().getFullYear();

      // If the selected period is the current year, then the right arrow should not be visible
      if (this.selectedPeriodType === 'Calendar Year') {
        return this.selectedPeriod.year < currentYear;
      }
      // If the selected period is month, then the right arrow should not be visible if the selected year is the current year or if the selected month is the current month
      else if (this.selectedPeriodType === 'Month') {
        return this.selectedPeriod.year < currentYear || (this.selectedPeriod.year === currentYear && this.selectedPeriod.month < new Date().getMonth() + 1);
      }
      // If the selected period is quarter, then the right arrow should not be visible if the selected year is the current year or if the selected quarter is the current quarter
      else if (this.selectedPeriodType === 'Quarter') {
        return this.selectedPeriod.year < currentYear || (this.selectedPeriod.year === currentYear && this.selectedPeriod.quarter < Math.floor((new Date().getMonth() + 3) / 3));
      }
      return true;
    },
  },
  watch: {
  },
  async mounted() {
    // Get 5 most recent years to populate the year dropdown
    let currentYear = new Date().getFullYear();
    for (let i = 0; i < 5; i++) {
      this.years.push({
        name: currentYear - i,
        value: currentYear - i
      });
    }
    // Load all init requests
    await Promise.all([
      this.getCompanyPharmacies(),
      this.getCampaignList(),
      this.getCampaignRecommendations(),
      this.getTemplateRecommendations(),
    ]);
    await this.getMessageStats();
    // await this.getTemplateList();
  },
  methods: {
    /**
     * Open the recommendations modal
     * @param {Object} recommendation - The recommendation to display - if null do not preselect
     */
    openRecommendationsModal(recommendation = {}) {
      this.$store.dispatch('getRefreshToken');
      this.selectedRecommendation = recommendation;
      this.displayRecommendationsModal = true;
    },
    /**
     * Open the campaigns modal
     * @param {Object} campaign - The campaign to display - if null do not preselect
     */
    openCampaignsModal(campaign = {}) {
      this.$store.dispatch('getRefreshToken');
      this.selectedCampaign = campaign;
      this.displayCampaignsModal = true;
    },
    /**
     * Open the time period modal
     */
    openTimePeriodModal() {
      this.$store.dispatch('getRefreshToken');
      this.displayTimePeriodModal = true;
    },
    /**
     * Open the templates modal
     */
    openTemplatesModal() {
      this.$store.dispatch('getRefreshToken');
      this.displayTemplatesModal = true;
    },
    /**
     * On Recommended Campaign Created, reload recommended campaigns and close modals
     */
    async recommendedCampaignCreated() {
      this.closeModal();
      await Promise.all([
        this.getCampaignList(),
        this.getCampaignRecommendations(),
      ]);
    },
    /**
     * On Recommended Template Created, reload recommended templates and close modals
     */
    async recommendedTemplateCreated() {
      this.closeModal();
      await this.getTemplateRecommendations();
    },
    /**
     * Close any open modals
     */
    closeModal() {
      this.displayRecommendationsModal = false;
      this.displayCampaignsModal = false;
      this.displayTemplatesModal = false;
      this.displayTimePeriodModal = false;
    },
    /**
     * Row class function for the data table
     */
    rowClass(data) {
      // Hide the expander for the total row
      return data.rank === "TOTAL" ? "no-expander pharmacies-totals-row" : "pharmacies-row";
    },
    /**
     * Get the list of campaigns for the current logged in enterprise user
     * @returns {Promise} - The promise for the campaign/enterprise/list request
     */
    async getCampaignList() {
      this.loadingCampaignList = true;
      this.$store.dispatch('getRefreshToken');

      // Clear the campaigns if it is the first page
      if (this.campaignListCurrentPage === 1) {
        this.campaigns = [];
      }

      try {
        const res = await axios.post('/api/campaign/enterprise/list', this.campaignListPayLoad, this.$store.state.header);
        this.handleGetCampaignListSuccess(res);
      } catch (err) {
        this.handleGetCampaignListError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the get campaign list success case
     * @param {Object} res
     */
     handleGetCampaignListSuccess(res) {
      console.log('The get campaign list response is', res.data);
      this.campaigns = [...this.campaigns, ...res.data.campaigns];
      this.campaignListCurrentPage = res.data.current_page;  
      this.campaignListPageCount = res.data.page_count;
      this.campaignListPerPage = res.data.per_page;
      this.loadingCampaignList = false;
    },
    /**
     * Handle the get campaign list error case
     * @param {Object} err
     */
     handleGetCampaignListError(err) {
      console.log('The get campaign list error is', err);
      this.campaignListError = err;
      this.loadingCampaignList = false;
    },
    /**
     * Remove the campaign from the list of campaigns
     * @param {String} campaign_id - The campaign id to remove
     */
    campaignRemoved(campaign_id) {
      this.campaigns = this.campaigns.filter(x => x.campaign_id !== campaign_id);
    },
    /**
     * Display the page
     * @param {Number} page - The page number to display
     */
    displayPage(page) {
      this.campaignListCurrentPage = page;
      this.getCampaignList();
    },
    /**
     * Get the recommended campaigns for the current logged in company
     */
    async getCampaignRecommendations() {
      this.loadingCampaignRecommendations = true;
      this.$store.dispatch('getRefreshToken');
      this.campaignRecommendations = [];

      try {
        const res = await axios.post('/api/campaign-recommendation/company/list', this.recommendationsPayLoad, this.$store.state.header);
        this.handleGetCampaignRecommendationsSuccess(res);
      } catch (err) {
        this.handleGetCampaignRecommendationsError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the get campaign recommendations success case
     * @param {Object} res
     */
    handleGetCampaignRecommendationsSuccess(res) {
      console.log('The get campaign recommendations response is', res.data);
      let campaigns = res.data.campaigns;
      // add the new key to the recommendations
      campaigns.forEach((x) => {
        x.type = "campaign";
        x.id = x.campaign_id;
        if (this.deploymentEnvironment === 'staging' || this.deploymentEnvironment === 'development') {
          // add the new key to the recommendations if the deployment environment is staging or development
          x.new = true;
        }
      });
      this.campaignRecommendations = campaigns;
      this.loadingCampaignRecommendations = false;
    },
    /**
     * Handle the get campaign recommendations error case
     * @param {Object} err
     */
    handleGetCampaignRecommendationsError(err) {
      console.log('The get campaign recommendations error is', err);
      this.recommendationsError = err;
      this.loadingCampaignRecommendations = false;
    },
    /**
     * Get the recommended templates for the current logged in company
     */
    async getTemplateRecommendations() {
      this.loadingTemplateRecommendations = true;
      this.$store.dispatch('getRefreshToken');
      this.templates = [];
      this.templateRecommendations = [];

      try {
        const res = await axios.post('/api/template-recommendation/company/list', this.recommendationsPayLoad, this.$store.state.header);
        this.handleGetTemplateRecommendationsSuccess(res);
      } catch (err) {
        this.handleGetTemplateRecommendationsError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the get template recommendations success case
     * @param {Object} res
     */
    handleGetTemplateRecommendationsSuccess(res) {
      console.log('The get template recommendations response is', res.data);
      let templates = res.data.templates;
      // add the new key to the recommendations
      templates.forEach((x) => {
        x.type = "template";
        x.id = x.template_id;
        if (this.deploymentEnvironment === 'staging' || this.deploymentEnvironment === 'development') {
          // add the new key to the recommendations if the deployment environment is staging or development
          x.new = true;
        }
      });
      // extend to the existing list of recommendations
      this.templateRecommendations = templates;
      this.loadingTemplateRecommendations = false;
    },
    /**
     * Handle the get template recommendations error case
     * @param {Object} err
     */
    handleGetTemplateRecommendationsError(err) {
      console.log('The get template recommendations error is', err);
      this.templateListError = err;
      this.loadingTemplateRecommendations = false;
    },
    /**
     * Get the list of pharmacies for the campaign
     */
     async getCompanyPharmacies() {
      this.loadingPharmacies = true;
      this.$store.dispatch('getRefreshToken');
      this.loadingPharmaciesError = '';
      this.companyPharmacies = [];
      
      try {
        const res = await axios.post('/api/get-pharmacies', {}, this.$store.state.header);
        this.handleGetCompanyPharmaciesSuccess(res);
      } catch (err) {
        this.handleGetCompanyPharmaciesError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response from the get pharmacies endpoint
     * @param {Object} res
     */
    handleGetCompanyPharmaciesSuccess(res) {
      console.log('The get pharmacies response is: ', res);
      let pharmacies = res.data.data;

      pharmacies.forEach(pharmacy => {
        this.companyPharmacies.push({
          pharmacy_name: pharmacy.name,
          pharmacy_id: pharmacy.uuid,
        });
      });

      this.companyPharmaciesFiltered = this.companyPharmacies;
      this.loadingPharmacies = false;
    },
    /**
     * Handle the error response from the get pharmacies endpoint
     * @param {Object} err
     */
    handleGetCompanyPharmaciesError(err) {
      console.log('The get pharmacies error is: ', err);
      this.loadingPharmacies = false;
      this.loadingPharmaciesError = err;
    },
    /**
     * Get the message stats from the get message stats endpoint
     *
     * @returns {Promise} - The promise for the get message stats endpoint
     */
    async getMessageStats(event = {}) {
      this.loadingMessageStats = true;
      this.$store.dispatch('getRefreshToken');
      this.messageStats = [];

      // If event is not empty, then it is a page change event
      event.page !== undefined ? this.messageStatsCurrentPage = event.page + 1 : this.messageStatsCurrentPage = 1
      
      try {
        const res = await axios.post('/api/enterprise-message-stats', this.messageStatsPayLoad, this.$store.state.header);
        this.handleGetMessageStatsSuccess(res);
      } catch (err) {
        this.handleGetMessageStatsError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the get message stats success case
     * @param {Object} res
     */
    handleGetMessageStatsSuccess(res) {
      console.log('The get message stats response is', res.data);
      var totalMessageStats = {};
      this.messageStats = res.data.pharmacies.map(x => ({...x}));
      this.messageStatsCurrentPage = res.data.current_page;
      this.messageStatsPerPage = res.data.per_page;
      this.messageStatsPageCount = res.data.page_count;

      // Format the totals data
      totalMessageStats.rank = "TOTAL";
      totalMessageStats.pharmacy_name = res.data.total_pharmacies +" Pharmacies";
      totalMessageStats.sms = res.data.total_sms;
      totalMessageStats.email = res.data.total_email;
      totalMessageStats.reach = res.data.total_reach;
      totalMessageStats.cost = res.data.total_cost;
      totalMessageStats.revenue = res.data.total_revenue;
      totalMessageStats.roi = res.data.total_roi;
      
      this.messageStats.unshift(totalMessageStats);
      this.loadingMessageStats = false;
    },
    /**
     * Handle the get message stats error case
     * @param {Object} err
     */
    handleGetMessageStatsError(err) {
      console.log('The get message list error is', err);
      this.loadingMessageStats = false;
      this.messsageStatsError = err;
    },
    /**
     * Get the message stats for a pharmacy
     * @returns {Promise} - The promise for the get message stats endpoint
     */
    async onMessageStatsExpand(event) {
      event.data.pharmacy_message_stats_loading = true;

      try {
        const res = await axios.post('/api/enterprise-pharmacy-message-stats', {
          "pharmacy_id": event.data.pharmacy_id,
          "year": this.selectedPeriod.year,
          "month": this.selectedPeriod.month,
          "quarter": this.selectedPeriod.quarter,
        }, this.$store.state.header);
        // filter pharmacy with id 
        Object.assign(event.data, res.data);
        event.data.pharmacy_message_stats_loading = false;
      } catch (err) {
        event.data.pharmacy_message_stats_loading = false;
        console.log('The pharmacy message stats error is', err);
      }
    },
    /**
     * Change the time period
     * @param {Number} direction - The direction to change the time period
     */
    changeTimePeriod(direction) {
      // If the selected period type is Calendar year, then change the year
      if (this.selectedPeriodType === 'Calendar Year') {
        this.selectedPeriod.year += direction;
      }
      // If the selected period is month, then change the month
      else if (this.selectedPeriodType === 'Month') {
        this.selectedPeriod.month += direction;
        // If the month is less than 1, then change the year to the previous year and set the month to 12
        if (this.selectedPeriod.month < 1) {
          this.selectedPeriod.month = 12;
          this.selectedPeriod.year -= 1;
        }
        // If the month is greater than 12, then change the year to the next year and set the month to 1
        else if (this.selectedPeriod.month > 12) {
          this.selectedPeriod.month = 1;
          this.selectedPeriod.year += 1;
        }
      }
      // If the selected period is quarter, then change the quarter
      else if (this.selectedPeriodType === 'Quarter') {
        this.selectedPeriod.quarter += direction;
        // If the quarter is less than 1, then change the year to the previous year and set the quarter to 4
        if (this.selectedPeriod.quarter < 1) {
          this.selectedPeriod.quarter = 4;
          this.selectedPeriod.year -= 1;
        }
        // If the quarter is greater than 4, then change the year to the next year and set the quarter to 1
        else if (this.selectedPeriod.quarter > 4) {
          this.selectedPeriod.quarter = 1;
          this.selectedPeriod.year += 1;
        }
      }
      this.getMessageStats();
    },
    /**
     * Update the filters when the user confirms them in the time period modal
     * @param {Object} value 
     */
     updateFilters(value) {
      this.selectedPeriodType = value.selectedPeriodType; 
      this.selectedPeriod = value.selectedPeriod;
      this.getMessageStats();
    },
    /**
     * Search for campaigns based on the search string
     * @returns {Promise} - The promise for the template/enterprise/search request
     */
     searchPharmacies() {
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        this.$store.dispatch('getRefreshToken');
        // Filter pharmacies based on the search string
        this.companyPharmaciesFiltered = this.companyPharmacies.filter(x => x.pharmacy_name.toLowerCase().includes(this.searchString.toLowerCase()));
        this.getMessageStats();
      }, 400);
    },
    /**
     * Lazy load the active campaigns
     * @param {Object} event - The event object from the lazy load event
     */
    async lazyLoadActiveCampaigns(event) {
      // Get the past position of the scroll
      let lastPos = this.$refs.virtualScroller.lastScrollPos;
      if (event.last >= this.campaigns.length && this.campaignListCurrentPage < this.campaignListPageCount) {
        this.campaignListCurrentPage += 1;
        await this.getCampaignList();
        // Scroll to the last position
        this.$refs.virtualScroller.scrollTo({
          left: 0,
          top: lastPos
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/main.scss";

.full-height {
  height: 100%;
}
:deep(tr.no-expander > td .p-row-toggler) {
  display: none;
}
.flex-container {
  display: flex;
  flex-direction: row;
  gap: 40px;
  flex: 1;
  min-height: 400px;
}

.left-col {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-bottom: 1em;
  flex: 1;
  min-width: 400px;
}

.right-col {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 1em;
  flex: 2;
  min-width: 400px;
}

.flex-section {
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
  gap: 8px;
}

.flex-section-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 35px;
  gap: 11px;
}

.flex-section h3 {
  font-size: 14pt;
  margin: 0;
}

.flex-section h4 {
  font-size: 12pt;
  margin: 0.5em 0 0.67em 0;
}

.p-progress-spinner {
  margin: 0 0 0 auto;
}

.virtual-scroll {
  flex: 1;
}

.campaign-item {
  background-color: white;
  border: 1px solid $bordergrey;
  border-radius: 5px;
  padding: 0.5em 1em 0.5em 1em;
  cursor: pointer;
  margin-bottom: 10px;
  height: 120px;
}

.campaign-item h4 {
  border-bottom: 2px solid $midgrey;
}

.goal-text {
  font-weight: 700;
  color: $strongblue;
}

.time-remaining-text {
  font-size: 12pt;
  font-weight: 600;
  align-self: center;
  margin-left: auto;
}

.achievement-row {
  font-weight: 600;
}

.flex-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.recommendation-item {
  background-color: white;
  border: 1px solid $phoneorders;
  border-radius: 5px;
  padding: 0.5em 1em 0.5em 1em;
  cursor: pointer;
  font-weight: 500;
}

.recommendation-item h4 {
  margin: 0;
}

.flex-item {
  flex: 1;
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 60px;
  height: 60px;
  margin: 10px 0 10px 0;

  h1 {
    margin: 0;
  }
}

.nav-functions {
  align-self: flex-start;
  z-index: 3;
}

.p-selectbutton .p-button .p-password-input {
  border: none !important;
}

.flex-expand {
  flex: 1;
}

.flex-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tag-success {
  background-color: $complete;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.recommendation-type {
  color: #BDC4C4;
  font-weight: 600;
}

:deep(.p-datatable-table) {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-weight: 500;
}

.p-datatable {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
}

.p-datatable :deep(.p-paginator-bottom) {
  margin-top: auto;
}

:deep(.pharmacies-row) {
  background-color: $grey !important;
  font-size: 1em;
  font-weight: 500;
  border: 1px solid $midgrey !important;
  border-radius: 6px !important;
  height: 3.5em;
}

:deep(.pharmacies-totals-row) {
  background-color: $darkgrey;
  margin:1em;
}

:deep(.p-datatable-row-expansion td) {
  padding: 0;
}
</style>